<template>
  <div class="competition">
    <banner />
    <div class="scroll-menu">
      <div class="item" @click="goMenu(1)">主题</div>
      <div class="item" @click="goMenu(2)">组织机构</div>
      <div class="item" @click="goMenu(3)">赛事流程</div>
      <div class="item" @click="goMenu(4)">奖项设置</div>
      <p><span></span></p>
    </div>
    <div class="text-item">
      <div class="content">
        <div class="c-title anime">
          介绍
          <span></span>
        </div>
        <div class="desc anime">
          当下正值高校毕业生陆续完成毕业设计、走出校门求职应聘的旺季。本次大赛以《吉林省工业协会庆祝建党100周年》-大学
          生毕业设计作品联展赛为题。参赛的学生可以通过本次赛事结识到更多的挚友和导师，一个展现毕业设计作品的新舞台，成
          为中国最具影响力的《十四五开局之年》-大学生毕业设计联展赛，不但可以提高自己全方面的综合素质，在毕业就业方向会
          有很大的帮助，为提前步入社会工作奠定光芒闪耀的的基础
        </div>
      </div>
    </div>
    <div class="text-item" id="menu2">
      <div class="content">
        <div class="c-title anime">
          组织机构
          <span></span>
        </div>
        <div class="desc anime c">
          <b>主办单位</b>：吉林省工业协会、吉林大学<br />
          协办单位：吉林师范大学、吉林建筑大学、吉林动画学院、吉林化工学院、吉林工程技术师范学院、长春大学、
          长春工业大学、长春理工大学、长春大学旅游学院、长春工程学院、长春光华学院、长春建筑学院、北华大学、延边大学<br />
          <b>技术支持单位</b>：易造工业互联网<br />
          <b>媒体支持单位</b>：吉林日报、腾讯新闻、新浪 <br />
          <b>奖项支持单位</b>：易造科技（长春）有限公司
        </div>
      </div>
    </div>
    <div class="flow" id="menu3">
      <div class="content">
        <div class="c-title anime">赛事流程 <span></span></div>
        <div class="flow-main anime">
          <p>
            ------------------------------------------------------------------
          </p>
          <div class="item">
            <p>1</p>
            <span>在线注册报名</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item t">
            <span>作品提交</span>
            <p>2</p>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item">
            <p>3</p>
            <span>初评</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item t">
            <span>佳作展</span>
            <p>4</p>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item">
            <p>5</p>
            <span>终评</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item t">
            <span>颁奖典礼</span>
            <p>6</p>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item">
            <p>7</p>
            <span>优秀作品展示</span>
          </div>
        </div>
      </div>
    </div>
    <div class="award" id="menu4">
      <div class="content">
        <div class="c-title anime">
          奖项设置
          <span></span>
        </div>
        <div class="desc anime c">
          本届大赛由吉林省工业协会主办、工业协会会员支持下，由易造工业互联网承办本次大赛。
        </div>
        <div class="list anime">
          <div class="item" v-for="item in awardList" :key="item.id">
            <p>{{ item.title }}</p>
            <p>{{ item.num }}</p>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from "./components/banner";
export default {
  components: { banner },
  data() {
    return {
      awardList: [
        { id: 1, title: "最佳优秀设计奖", num: "1项", content: "3000RMB/项" },
        { id: 2, title: "金奖", num: "8项", content: "2000RMB/项" },
        { id: 3, title: "银奖", num: "10项", content: "1000RMB/项" },
        { id: 4, title: "优胜奖", num: "20项", content: "证书+奖杯" },
        { id: 5, title: "最佳指导导师奖", num: "5项", content: "证书+奖杯" },
        { id: 6, title: "最佳组织奖", num: "1项", content: "证书+奖杯" },
      ],
    };
  },
  methods:{
    goMenu(i){
      let top = {
        1:0,
        2:$('#menu2').offset().top-190,
        3:$('#menu3').offset().top-190,
        4:$('#menu4').offset().top-190
      }
      $('body,html').animate({scrollTop:top[i]},500);
    }
  },
  mounted() {
    $(window).on('scroll',function(){
      $('.scroll-menu .item').each(function(){
        if($('body,html').scrollTop()<$('#menu2').offset().top-200){
          $('.scroll-menu p').removeClass('on2 on3 on1');
        }
        if($('body,html').scrollTop()>$('#menu2').offset().top-200){
          $('.scroll-menu p').removeClass('on2 on3').addClass('on1');
        }
        if($('body,html').scrollTop()>$('#menu3').offset().top-200){
          $('.scroll-menu p').removeClass('on1 on3').addClass('on2');
        }
        if($('body,html').scrollTop()>$('#menu4').offset().top-200){
          $('.scroll-menu p').removeClass('on1 on2').addClass('on3');
        }
      })
    })
    this.$parent.finish ? this.$parent.finish() : null;
  },
};
</script>
<style lang="scss">
.competition {
  color: white;
  .c-title {
    text-align: center;
    font-size: 30px;
    color: #fffefe;
    span {
      width: 61px;
      height: 4px;
      display: block;
      background-color: $c;
      box-shadow: 0 0 15px 5px fade-out($color: $c2, $amount: 0.5);
      margin: 20px auto 0;
    }
  }
  .text-item {
    margin-top: 5vw;
    .content {
      .desc {
        margin-top: 4vw;
        font-size: 20px;
        line-height: 3vw;
        letter-spacing: 2px;
        &.c {
          text-align: center;
        }
      }
    }
  }
  .flow {
    margin-top: 5vw;
    .flow-main {
      margin-top: 4vw;
      display: flex;
      justify-content: space-around;
      overflow: hidden;
      position: relative;
      align-items: center;
      & > p {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 20px;
        text-align: center;
        color: #ffffff;
        letter-spacing: 4px;
        line-height: 115px;
      }
      .icon {
        position: relative;
        z-index: 9;
      }
      .item {
        text-align: center;
        padding-top: 25px;
        position: relative;
        z-index: 9;
        &.t {
          padding-top: 0;
          padding-bottom: 25px;
        }
        p {
          width: 30px;
          height: 30px;
          background-image: linear-gradient(90deg, #2788ff 0%, #272fff 100%),
            linear-gradient(#272fff, #272fff);
          text-align: center;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
          font-size: 18px;
          color: white;
          margin: 20px auto;
        }
        span {
          display: block;
          font-size: 20px;
        }
      }
    }
  }
  .award {
    margin-top: 5vw;
    .desc {
      margin-top: 4vw;
      font-size: 20px;
      line-height: 100px;
      letter-spacing: 2px;
      &.c {
        text-align: center;
      }
    }
    .list {
      display: flex;
      justify-content: space-around;
      .item {
        width: calc(90% / 6);
        background-color: #100f1e;
        border: solid 6px #272fff;
        padding: 25px 5px;
        @include box();
        p {
          margin-top: 25px;
          font-size: 18px;
          text-align: center;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
.scroll-menu {
  position: fixed;
  left: 5vw;
  top: 20vw;
  z-index: 999;
  padding-left: 30px;
  p {
    top: 5px;
    left: 0;
    bottom: 5px;
    position: absolute;
    border-left: 1px solid #e5e5e5;
    &.on1{
      span{
        top: 69px;
      }
    }
    &.on2{
      span{
        top: 138px;
      }
    }
    &.on3{
      span{
        top: 207px;
      }
    }
    span {
      position: absolute;
      display: block;
      top: -2px;
      left: 50%;
      transition: 0.3s top;
      transform: translateX(-50%);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $c;
    }
  }
  .item {
    font-size: 20px;
    color: #fffefe;
    font-weight: bold;
    line-height: 20px;
    margin-top: 50px;
    letter-spacing: 2px;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &.on {
      color: $c;
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>